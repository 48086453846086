<template>
  <div class="flex-container non-scrollable">
    <page-title value="Facture en attente"/>
    <div class="flex-container non-scrollable" style="height: 50vh !important;">
      <DataTable ref="dt" :value="invoices" :paginator="true" class="p-datatable-sm editable-cells-table" :rows="constants.defaultRowNumber"
                 dataKey="id" :rowHover="true" v-model:filters="filters" filterDisplay="menu" :loading="loading" @row-click="clickInvoice"
                 paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="constants.rowsPerPageOptions"
                 :currentPageReportTemplate="$t('datatable.currentPageReportTemplate', {first: '{first}', last: '{last}', totalRecords: '{totalRecords}', target: $t('datatable.target.data')})"
                 scrollable scroll-height="flex" v-model:selection="selectedInvoices">
        <template #header>
          <div class="p-d-flex flex">
          <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputText v-model="filters['global'].value" :placeholder="$t('search')" />
          </span>
          </div>
        </template>
        <template #empty>
          {{ $t('datatable.notFound', {target: $t('datatable.target.data')}) }}
        </template>
        <template #loading>
          {{ $t('datatable.load', {target: $t('datatable.target.data')}) }}
        </template>
        <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
        <Column field="moisFacturation" :header="$t('month').toUpperCase()" sortable filterField="moisFacturation" sortField="moisFacturation" :showFilterMatchModes="false" :show-apply-button="false" :show-clear-button="false">
          <template #body="{data}">
          <span class="uppercase">
            {{
              data.moisFacturation.toLocaleDateString('fr-FR', {
                month: 'long',
                year: 'numeric',
              })
            }}
          </span>
          </template>
          <template #filter="{filterModel, filterCallback}">
            <div class="p-mb-3 p-text-bold mb-3">{{ $t('month') }}</div>
            <MultiSelect @change="filterCallback()" v-model="filterModel.value" :options="commonFilter('moisFacturation')" option-value="value" optionLabel="value" :placeholder="$t('all')" class="p-column-filter" style="max-width: 14rem; min-width: 14rem">
              <template #option="slotProps">
                <div>
                <span class="uppercase">{{slotProps.option.value.toLocaleDateString('fr-FR', {
                  month: 'long',
                  year: 'numeric',
                })}}</span>
                </div>
              </template>
              <template #value="slotProps">
                <div>
                  <span class="uppercase">{{ slotProps.value ? slotProps.value.map((v) => v.toLocaleDateString('fr-FR', {month: 'long',year: 'numeric',})).join(', ') : 'Mois' }}</span>
                </div>
              </template>
            </MultiSelect>
          </template>
        </Column>
        <Column field="independent.last_name" :header="$t('lastname').toUpperCase()" sortable filterField="independent.last_name" sortField="independent.last_name" :showFilterMatchModes="false" :show-apply-button="false" :show-clear-button="false">
          <template #filter="{filterModel, filterCallback}">
            <div class="p-mb-3 p-text-bold mb-3">{{ $t('lastname') }}</div>
            <MultiSelect @change="filterCallback()" v-model="filterModel.value" :options="commonFilter('independent.last_name')" option-value="value" optionLabel="value" :placeholder="$t('all')" class="p-column-filter" style="max-width: 14rem; min-width: 14rem">
              <template #option="slotProps">
                <div>
                  <span>{{slotProps.option.value}}</span>
                </div>
              </template>
            </MultiSelect>
          </template>
        </Column>
        <Column field="independent.first_name" :header="$t('firstname').toUpperCase()" sortable filterField="independent.first_name" sortField="independent.first_name" :showFilterMatchModes="false" :show-apply-button="false" :show-clear-button="false">
          <template #filter="{filterModel, filterCallback}">
            <div class="p-mb-3 p-text-bold mb-3">{{ $t('firstname') }}</div>
            <MultiSelect @change="filterCallback()" v-model="filterModel.value" :options="commonFilter('independent.first_name')" option-value="value" optionLabel="value" :placeholder="$t('all')" class="p-column-filter" style="max-width: 14rem; min-width: 14rem">
              <template #option="slotProps">
                <div>
                  <span>{{slotProps.option.value}}</span>
                </div>
              </template>
            </MultiSelect>
          </template>
        </Column>
        <Column field="mission.clientContract.name" :header="$t('client').toUpperCase()" sortable filterField="mission.clientContract.name" sortField="mission.clientContract.name" :showFilterMatchModes="false" :show-apply-button="false" :show-clear-button="false">
          <template #filter="{filterModel, filterCallback}">
            <div class="p-mb-3 p-text-bold mb-3">{{ $t('client') }}</div>
            <MultiSelect @change="filterCallback()" v-model="filterModel.value" :options="commonFilter('mission.clientContract.name')" option-value="value" optionLabel="value" :placeholder="$t('all')" class="p-column-filter" style="max-width: 14rem; min-width: 14rem">
              <template #option="slotProps">
                <div>
                  <span>{{slotProps.option.value}}</span>
                </div>
              </template>
            </MultiSelect>
          </template>
        </Column>
        <Column field="mission.jsonCost.settlementPeriod.value" :header="$t('settlementDelay').toUpperCase()" sortable filterField="mission.jsonCost.settlementPeriod.value" sortField="mission.jsonCost.settlementPeriod.value" :showFilterMatchModes="false" :show-apply-button="false" :show-clear-button="false">
          <template #body="{data}">
            {{ data.mission?.jsonCost?.settlementPeriod.value }} {{ data.mission?.jsonCost?.settlementPeriod.unit }}
          </template>
          <template #filter="{filterModel, filterCallback}">
            <div class="p-mb-3 p-text-bold mb-3">{{ $t('settlementDelay') }}</div>
            <MultiSelect @change="filterCallback()" v-model="filterModel.value" :options="commonFilter('mission.jsonCost.settlementPeriod.value')" option-value="value" optionLabel="value" :placeholder="$t('all')" class="p-column-filter" style="max-width: 14rem; min-width: 14rem">
              <template #option="slotProps">
                <div>
                  <span>{{slotProps.option.value}} Jours</span>
                </div>
              </template>
            </MultiSelect>
          </template>
        </Column>
        <Column field="ht" :header="$t('montantHT').toUpperCase()" sortable filterField="ht" sortField="ht" :showFilterMatchModes="false" :show-apply-button="false" :show-clear-button="false">
          <template #body="{data}">
            {{getEuroFormatFromEuro(data.ht, true)}}
          </template>
          <template #filter="{filterModel, filterCallback}">
            <div class="p-mb-3 p-text-bold mb-3">{{ $t('montantHT') }}</div>
            <MultiSelect @change="filterCallback()" v-model="filterModel.value" :options="commonFilter('ht')" option-value="value" optionLabel="value" :placeholder="$t('all')" class="p-column-filter" style="max-width: 14rem; min-width: 14rem">
              <template #option="slotProps">
                <div>
                  {{getEuroFormatFromEuro(slotProps.option.value, true)}}
                </div>
              </template>
            </MultiSelect>
          </template>
        </Column>
        <template #footer>
          <div class="flex flex-row-reverse mt-5">
            <Button v-if="selectedInvoices?.length > 0" class="p-button-danger mr-2" type="button" @click="deleteInvoices">Supprimer les factures</Button>
          </div>
        </template>
      </DataTable>
    </div>
  </div>
</template>

<script>
import {FilterMatchMode} from 'primevue/api';
import {mapGetters, mapState} from 'vuex'
import { getEuroFormatFromEuro } from '@/utils/Money'
import PageTitle from "@/components/PageTitle.vue";
export default {
  name: "FactureReglements",
  components: {PageTitle},
  data() {
    return {
      selectedInvoices: [],
      filters: {
        'global': {value: null, matchMode: null},
        'independent.last_name': {value: null, matchMode: FilterMatchMode.IN},
        'independent.first_name': {value: null, matchMode: FilterMatchMode.IN},
        'moisFacturation': {value: null, matchMode: FilterMatchMode.IN},
        'mission.clientContract.name': {value: null, matchMode: FilterMatchMode.IN},
        'mission.jsonCost.settlementPeriod.value': {value: null, matchMode: FilterMatchMode.IN},
        'ht': {value: null, matchMode: FilterMatchMode.IN},
      },
      loading: true,
    }
  },
  computed: {
    ...mapState({
      constants: state => state.constants,
    }),
    ...mapGetters({
      invoices: 'invoice/getInvoices'
    })
  },
  async mounted() {
    await this.loadInvoices()
    this.loading = false
  },
  methods: {
    deleteInvoices () {
      this.$confirm.require({
        header: 'Supprimer les factures',
        message: 'Vous êtes sur le point de supprimer les factures sélectionnées. Les services associées seront à nouveau éditables.',
        acceptLabel: 'Confirmer',
        rejectLabel: 'Annuler',
        accept: async () => {
          this.$store.state.misc.loading = true
          for (const invoice of this.selectedInvoices) {
            await this.$store.dispatch('invoice/removeInvoice', invoice.id)
          }
          this.selectedInvoices = []
          await this.loadInvoices()
          this.$toast.add({severity:'success', summary:'Succès', detail:'Factures supprimées', life: 3000});
          this.$store.state.misc.loading = false
          this.$confirm.close()
        },
        reject: () => {
          this.$confirm.close()
        }
      })
    },
    getEuroFormatFromEuro (price, withUnit) {
      return getEuroFormatFromEuro(price, withUnit)
    },
    async loadInvoices () {
      await this.$store.dispatch('invoice/getAllInvoices', {status: 'draft'})
    },
    async clickInvoice (invoice) {
      if (this.selectedInvoices.length > 0) return
      this.$store.state.misc.loading = true
      this.$router.push({name: 'FactureAttenteId', params: { id: invoice.data.id }})
    },
    commonFilter (field) {
      let array = this.invoices.map((s) => {
        let fields = field.split('.')
        let v = s
        fields.forEach((f) => {
          v = v[f]
        })
        return v
      })
      let filterArray = []
      array.forEach((item) => {
        if (!filterArray.find((fa) => fa.value === item || Math.abs(fa.value - item) === 0)) {
          filterArray.push({
            value: item
          })
        }
      })
      return filterArray
    }
  }
}
</script>
